var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-snackbar',{attrs:{"color":_vm.appColor,"right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"text-left":"","align-self-center":""}},[_vm._v(_vm._s(_vm.msg))]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":""}},[(_vm.appUser === 'EmergencyUser' || _vm.appUser === 'range' || _vm.appUser === 'division' || _vm.appUser === 'RRT')?_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"pa-2",staticStyle:{"cursor":"pointer"},attrs:{"elevation":"2","ripple":false,"tile":""},on:{"click":function($event){return _vm.$router.push({ path: '/officers/releaseCase/', query: { id: _vm.caseDetails._id, name: _vm.caseDetails.animalId.name, location: _vm.caseDetails.locationname, date: _vm.caseDetails.reportTime, animalid: _vm.caseDetails.animalId._id } })}}},[_c('span',{staticStyle:{"font-family":"sofiaProSemiBold","color":"#000","font-size":"14px"}},[_vm._v(" Release ")])])],1):_vm._e(),(_vm.appUser === 'admin')?_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"pa-2",staticStyle:{"cursor":"pointer"},attrs:{"elevation":"2","ripple":false,"tile":""},on:{"click":function($event){return _vm.$router.push({ path: '/Admin/releaseCase/', query: { id: _vm.caseDetails._id, name: _vm.caseDetails.animalId.name, location: _vm.caseDetails.locationname, date: _vm.caseDetails.reportTime } })}}},[_c('span',{staticStyle:{"font-family":"sofiaProSemiBold","color":"#000","font-size":"14px"}},[_vm._v(" Release ")])]),_c('v-dialog',{attrs:{"persistent":"","width":_vm.$vuetify.breakpoint.name == 'xs' ||
          _vm.$vuetify.breakpoint.name == 'sm' ||
          _vm.$vuetify.breakpoint.name == 'md'
            ? '100vw'
            : _vm.$vuetify.breakpoint.name == 'lg'
            ? '60vw'
            : '50vw'},model:{value:(_vm.closeDialogue),callback:function ($$v) {_vm.closeDialogue=$$v},expression:"closeDialogue"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-layout',{attrs:{"pa-4":"","wrap":"","justify-center":"","pa-8":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticClass:"pl-2 itemSubHeading",staticStyle:{"font-size":"24px"}},[_vm._v(" Release")])]),_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticClass:"pl-2 formClass",staticStyle:{"font-size":"13px"}},[_vm._v(" Release the case by filling the below form.")])]),_c('v-flex',{attrs:{"xs12":"","pt-4":""}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateInput.apply(null, arguments)}}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-textField',{staticClass:"formClass",attrs:{"outlined":"","dense":"","color":"#B1B1B1","co":"","placeholder":"Release Location","rules":[_vm.rules.required]},model:{value:(_vm.releaseData.address),callback:function ($$v) {_vm.$set(_vm.releaseData, "address", $$v)},expression:"releaseData.address"}})],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-select',{staticClass:"formClass",attrs:{"items":_vm.releaseTypes,"color":"#B1B1B1","item-text":"name","item-value":"_id","outlined":"","dense":"","placeholder":"Release Type","rules":[_vm.rules.required]},model:{value:(_vm.releaseData.releaseTypeId),callback:function ($$v) {_vm.$set(_vm.releaseData, "releaseTypeId", $$v)},expression:"releaseData.releaseTypeId"}})],1),_c('v-flex',{attrs:{"xs12":""}},[(
                      _vm.releaseData.releaseTypeId == '60a7ee0af637aa1ddb5f964a'
                    )?_c('v-select',{staticClass:"formClass",attrs:{"items":_vm.handOverTypes,"color":"#B1B1B1","outlined":"","dense":"","item-text":"name","item-value":"value","placeholder":"Handover To","rules":[_vm.rules.required]},on:{"change":function($event){return _vm.loadData(_vm.releaseData.handoverUsertype)}},model:{value:(_vm.releaseData.handoverUsertype),callback:function ($$v) {_vm.$set(_vm.releaseData, "handoverUsertype", $$v)},expression:"releaseData.handoverUsertype"}}):_vm._e(),_c('vue-element-loading',{attrs:{"active":_vm.appLoading1,"is-full-screen":false,"background-color":"#FFFFFF","color":_vm.appColor}})],1),_c('v-flex',{attrs:{"xs12":""}},[(
                      _vm.releaseData.handoverUsertype &&
                      _vm.releaseData.releaseTypeId == '60a7ee0af637aa1ddb5f964a'
                    )?_c('v-select',{staticClass:"formClass",attrs:{"items":_vm.handoverData,"color":"#B1B1B1","outlined":"","dense":"","item-text":"fullName","item-value":"_id","placeholder":"Handover To","rules":[_vm.rules.required],"autocomplete":""},model:{value:(_vm.releaseData.handoverTo),callback:function ($$v) {_vm.$set(_vm.releaseData, "handoverTo", $$v)},expression:"releaseData.handoverTo"}}):_vm._e()],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{staticClass:"formClass",attrs:{"rows":"4","outlined":"","color":"#B1B1B1","hide-details":"","placeholder":"Remark"},model:{value:(_vm.releaseData.remarks),callback:function ($$v) {_vm.$set(_vm.releaseData, "remarks", $$v)},expression:"releaseData.remarks"}})],1)],1)],1),_c('v-layout',{attrs:{"pt-4":"","justify-center":""}},[_c('v-flex',{attrs:{"xs3":""}},[_c('v-btn',{attrs:{"block":"","small":"","color":"#FF6347","dark":"","ripple":false,"depressed":"","type":"submit"},on:{"click":function($event){_vm.closeDialogue = false}}},[_c('span',{staticClass:"text-capitalize"},[_vm._v(" CANCEL ")])])],1),_c('v-flex',{attrs:{"xs3":"","pl-3":""}},[_c('v-btn',{attrs:{"block":"","small":"","color":_vm.appColor,"dark":"","ripple":false,"depressed":"","type":"submit"},on:{"click":function($event){return _vm.validateInput()}}},[_c('span',{staticClass:"text-capitalize"},[_vm._v(" RELEASE CASE ")])])],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }