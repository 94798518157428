<template>
  <v-expansion-panel v-if="caseDetails.resolveDetails.length > 0">
    <v-expansion-panel-header expand-icon="mdi-menu-down">
      <span class="itemHeading">Resolved Details</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <!-- <v-layout>
        <v-flex>
          <ServerError v-if="ServerError" />
          <PageLoader :storage="appLoading" />
          <v-snackbar
            v-model="showSnackBar"
            color="#FDCE48"
            rightmore details
            :timeout="timeout"
          >
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: #fff">{{ msg }}</span>
              </v-flex>
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: #fff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
        </v-flex>
      </v-layout> -->
      <v-layout
        wrap
        pt-4
        v-for="(item, i) in caseDetails.resolveDetails"
        :key="i"
      >
        <!-- <v-flex xs12 text-right>
          <EditDetails :caseDetails="caseDetails" @stepper="winStepper" />
        </v-flex> -->
        <v-flex
          xs12
          text-right
          v-if="
            item.isHandover === false &&
            (appUser === 'range' ||
              appUser === 'division' ||
              appUser === 'EmergencyUser' ||
              appUser === 'RRT') &&
            caseDetails.status !== 'Released'
          "
        >
          <span
            class="appColor"
            style="font-size: 12px; font-family: sofiaProBold; cursor: pointer"
            @click="
              $router.push({
                path: '/officers/editresolveCase/',
                query: {
                  id: item._id,
                  name: item.animalId.name,
                  location: item.locationname,
                  date: item.reportTime,
                  animalid: item.animalId._id,
                },
              })
            "
            >Edit Details</span
          >
        </v-flex>
        <v-flex
          xs12
          text-right
          v-if="
            item.isHandover === false &&
            appUser === 'admin' &&
            item.isReleased === false
          "
        >
          <span
            class="appColor"
            style="font-size: 12px; font-family: sofiaProBold; cursor: pointer"
            @click="
              $router.push({
                path: '/admin/editresolveCase/',
                query: {
                  id: item._id,
                  name: item.animalId.name,
                  location: item.locationname,
                  date: item.reportTime,
                  animalid: item.animalId._id,
                },
              })
            "
            >Edit Details</span
          >
        </v-flex>
        <v-flex xs12>
          <v-layout pt-2 wrap>
            <v-flex xs9>
              <v-layout wrap>
                <v-flex xs12>
                  <span
                    style="
                      font-family: sofiaProLight;
                      font-size: 13px;
                      color: #4d4d4d;
                    "
                    >Resolved By</span
                  ><br />
                  <span
                    style="
                      font-family: sofiaProRegular;
                      font-size: 12px;
                      color: #000;
                      line-height: 0.5;
                    "
                  >
                    <span v-if="item.resolvedBy.role === 'User'">
                      <span>{{ item.resolvedBy.userId.name }}</span>
                    </span>
                    <span v-else-if="item.resolvedBy.role === 'MASTER'">
                      <span
                        >{{
                          item.resolvedBy.admin.masterName
                        }}&nbsp;(Admin)</span
                      >
                    </span>
                    <span v-else-if="item.resolvedBy.role === 'EmergencyUser'">
                      <span
                        >{{ item.resolvedBy.userId.name }}&nbsp;(Emergency
                        User)</span
                      >
                    </span>
                    <span v-else-if="item.resolvedBy.role === 'RANGE'">
                      <span
                        >{{
                          item.resolvedBy.range.accessCredentials.username
                        }}&nbsp;(Range Officer)</span
                      >
                    </span>
                    <span v-else-if="item.resolvedBy.role === 'DIVISION'">
                      <span
                        >{{
                          item.resolvedBy.division.accessCredentials.username
                        }}&nbsp;(Division Officer)</span
                      >
                    </span>
                    <span v-else-if="item.resolvedBy.role === 'RRT'">
                      <span v-if="item.resolvedBy.userId.name"
                        >{{ item.resolvedBy.userId.name }}&nbsp;(RRT)</span
                      >
                    </span>
                    <span v-else>UnKnown </span>
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3>
              <span
                class="float-right"
                v-if="item.resolvedTime"
                style="
                  font-family: sofiaProRegular;
                  color: #000;
                  font-size: 10px;
                "
              >
                {{ timeSince(item.resolvedTime) }}
              </span>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs6 v-if="item.isHandover">
              <span class="mainmainhead">Case Handovered To</span><br />
              <span class="subsubhead">
                <span v-if="item.handoverDetails">{{
                  item.handoverDetails.handoverplatform
                }}</span>
                <span v-else>UnKnown </span>
              </span>
            </v-flex>
            <v-flex
              xs6
              v-if="
                item.isHandover &&
                item.handoverDetails &&
                item.handoverDetails.handoverplatform
              "
            >
              <span class="mainmainhead"
                >{{ item.handoverDetails.handoverplatform }} Name</span
              ><br />
              <span class="subsubhead">
                <span
                  v-if="
                    item.handoverDetails.handoverplatform == 'Division' &&
                    item.handoverDetails.divisionId
                  "
                  >{{ item.handoverDetails.divisionId.divisionName }}</span
                >
                <span
                  v-else-if="
                    item.handoverDetails.handoverplatform == 'Range' &&
                    item.handoverDetails.rangeId
                  "
                  >{{ item.handoverDetails.rangeId.rangeName }}</span
                >
                <span
                  v-else-if="
                    item.handoverDetails.handoverplatform == 'RRT' &&
                    item.handoverDetails.RRT
                  "
                  >{{ item.handoverDetails.RRT.name }}</span
                >
                <span v-else>UnKnown</span>
              </span>
            </v-flex>
            <v-flex xs6 v-if="item.isHandover === false">
              <span class="mainmainhead">Resolved Location</span><br />
              <span class="subsubhead">
                <span v-if="item.resolvedLocationName">{{
                  item.resolvedLocationName
                }}</span>
                <span v-else>UnKnown </span>
              </span>
            </v-flex>
            <v-divider></v-divider>
            <v-flex xs6 pt-1>
              <span class="mainmainhead">Actual Incident Location</span><br />
              <span class="subsubhead">
                <span v-if="item.actualIncidentLocation">{{
                  item.actualIncidentLocation
                }}</span>
                <span v-else>- </span>
              </span>
            </v-flex>
            <v-flex xs6 pt-1>
              <span class="mainmainhead">Actual Incident Date & Time</span
              ><br />
              <span class="subsubhead">
                <span v-if="item.actualIncidentTime && item.actualIncidentDate">
                  {{ formatDate(item.actualIncidentDate) }} -
                  {{ formatTime24To12(item.actualIncidentTime) }}</span
                >
                <span v-else>- </span>
              </span>
            </v-flex>
            <v-divider></v-divider>

            <v-flex xs6 pt-1>
              <span class="mainmainhead">Sighting/Conflict</span><br />
              <span class="subsubhead">
                <span v-if="item.sightingOrConflict">{{
                  item.sightingOrConflict
                }}</span>
                <span v-else>- </span>
              </span>
            </v-flex>
            <v-flex xs6 pt-1>
              <span class="mainmainhead">Animal Type</span><br />
              <span class="subsubhead">
                <span v-if="item.animalId">{{ item.animalId.name }}</span>
                <span v-else>-</span>
              </span>
            </v-flex>
            <v-flex xs6 pt-1>
              <span class="mainmainhead">Animal Present Or Not?</span><br />
              <span class="subsubhead">
                <span v-if="item.animalPresentOrNot">Yes</span>
                <span v-else>No</span>
              </span>
            </v-flex>
            <v-flex xs6 pt-1>
              <span class="mainmainhead">Animal Activity</span><br />
              <span class="subsubhead">
                <span
                  v-if="item.animalActivityId && item.animalActivityId.name"
                  >{{ item.animalActivityId.name }}</span
                >
                <span v-else>-</span>
              </span>
            </v-flex>

            <v-flex xs6 pt-1>
              <span class="mainmainhead">RF/PA Name</span><br />
              <span class="subsubhead">
                <span v-if="item.RForPAName">{{ item.RForPAName }}</span>
                <span v-else>-</span>
              </span>
            </v-flex>
            <v-flex xs6 pt-1>
              <span class="mainmainhead">Animal Count</span><br />
              <span class="subsubhead">
                <span v-if="item.singleOrGroup">{{ item.singleOrGroup }}</span>
                <span v-else>- </span>
              </span>
            </v-flex>
            <!-- <v-flex xs6 pt-1 v-if="item.isAnimalDeath">
              <span class="mainmainhead">Animal Death</span><br />
              <span class="subsubhead">
                <span v-if="item.isAnimalDeath">Yes</span>
                <span v-else>No </span>
              </span>
            </v-flex>
            <v-flex xs6 pt-1 v-if="item.isAnimalInjured">
              <span class="mainmainhead">Animal Injured</span><br />
              <span class="subsubhead">
                <span v-if="item.isAnimalInjured">Yes</span>
                <span v-else>No </span>
              </span>
            </v-flex>
            <v-flex xs6 pt-1>
              <span class="mainmainhead">Breached Any Barrier?</span><br />
              <span class="subsubhead">
                <span v-if="item.isBreachedBarrier">Yes</span>
                <span v-else>No </span>
              </span>
            </v-flex>
            <v-flex xs6 pt-1 v-if="item.isCropDamage">
              <span class="mainmainhead">Crop Damage</span><br />
              <span class="subsubhead">
                <span v-if="item.isCropDamage">Yes</span>
                <span v-else>No </span>
              </span>
            </v-flex>
            <v-flex xs6 pt-1 v-if="item.isHumanDeath">
              <span class="mainmainhead">Human Death</span><br />
              <span class="subsubhead">
                <span v-if="item.isHumanDeath">Yes</span>
                <span v-else>No </span>
              </span>
            </v-flex>
            <v-flex xs6 pt-1 v-if="item.isHumanInjured">
              <span class="mainmainhead">Human Injury</span><br />
              <span class="subsubhead">
                <span v-if="item.isHumanInjured">Yes</span>
                <span v-else>No </span>
              </span>
            </v-flex>
            <v-flex xs6 pt-1 v-if="item.isInsideForest">
              <span class="mainmainhead">Is Animal inside forest or not?</span
              ><br />
              <span class="subsubhead">
                <span v-if="item.isInsideForest">Yes</span>
                <span v-else>No </span>
              </span>
            </v-flex>
            <v-flex xs6 pt-1 v-if="item.isLivestockDepredation">
              <span class="mainmainhead">Livestock Depredation</span><br />
              <span class="subsubhead">
                <span v-if="item.isLivestockDepredation">Yes</span>
                <span v-else>No </span>
              </span>
            </v-flex>
            <v-flex xs6 pt-1 v-if="item.isPropertyDamage">
              <span class="mainmainhead">Property Damage</span><br />
              <span class="subsubhead">
                <span v-if="item.isPropertyDamage">Yes</span>
                <span v-else>No </span>
              </span>
            </v-flex>
            <v-flex xs6 pt-1 v-if="item.isReleased">
              <span class="mainmainhead">Is Released or not?</span><br />
              <span class="subsubhead">
                <span v-if="item.isReleased">Yes</span>
                <span v-else>No </span>
              </span>
            </v-flex>
            <v-flex xs6 pt-1>
              <span class="mainmainhead">Resolved or not</span><br />
              <span class="subsubhead">
                <span v-if="item.isResolved">Yes</span>
                <span v-else>No </span>
              </span>
            </v-flex> -->
            <v-flex xs6 pt-1 v-if="item.isVeterinaryPresent">
              <span class="mainmainhead">Veterinary Present Or Not?</span><br />
              <span class="subsubhead">
                <span v-if="item.isVeterinaryPresent">Yes</span>
                <span v-else>No </span>
              </span>
            </v-flex>
            <v-flex xs12 pt-2 v-if="item.veterinaryRecommendations">
              <span class="mainmainhead">Veterinary Recommendations</span><br />
              <span class="subsubhead">
                <span v-if="item.veterinaryRecommendations">{{
                  item.veterinaryRecommendations
                }}</span>
                <span v-else>- </span>
              </span>
            </v-flex>
            <v-flex xs12 v-if="item && item.resolveImages.length > 0">
              <span
                style="
                  font-family: sofiaProLight;
                  font-size: 11px;
                  color: #4d4d4d;
                "
                >Resolved Images</span
              >
            </v-flex>
            <v-flex xs12 v-if="item && item.resolveImages.length > 0">
              <OwlCarousel
                :nav="false"
                :dots="true"
                :responsive="owlResponsive"
              >
                <template v-for="(item, i) in item.resolveImages">
                  <CarouselImage :key="i" v-if="item" v-bind:storage="item" />
                </template>
              </OwlCarousel>
            </v-flex>
            <v-flex xs12 pt-2>
              <v-layout>
                <v-flex xs6>
                  <span
                    class="appColor"
                    style="
                      font-size: 12px;
                      font-family: sofiaProBold;
                      cursor: pointer;
                    "
                    @click="showMoreDetails(item)"
                    >More Details</span
                  >
                </v-flex>
                <!-- <v-flex xs6 v-if="item.isStepsForMitigation">
                  <span
                    class="appColor"
                    style="
                      font-size: 12px;
                      font-family: sofiaProBold;
                      cursor: pointer;
                    "
                    @click="updatemitigationdialogbox(item)"
                    >Update Mitigation</span
                  >
                </v-flex> -->
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-expansion-panel-content>
    <v-dialog v-model="dialog" max-width="900px">
      <v-card>
        <v-card-title class="itemHeading">More Details</v-card-title>
        <v-card-text>
          <v-layout wrap justify-center>
            <v-flex xs12>
              <!-- Animal Details -->
              <v-layout wrap justify-start>
                <v-flex xs12>
                  <span class="subheading">Animal Details</span>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6>
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <span class="mainheading"
                        >Is Animal Inside Forest Or Not?</span
                      ><br />
                      <span class="mainheading">
                        <span>
                          <span v-if="selectedItem.isInsideForest">Yes</span>
                          <span v-else>No </span>
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6>
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <span class="mainheading">RF/PA Name</span><br />
                      <span class="mainheading">
                        <span>
                          <span v-if="selectedItem.RForPAName">{{
                            selectedItem.RForPAName
                          }}</span>
                          <span v-else>-</span>
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6>
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <span class="mainheading"
                        >Whether The Animal Was Present When Rescue Team
                        Reached?</span
                      ><br />
                      <span class="mainheading">
                        <span>
                          <span v-if="selectedItem.animalPresentOrNot"
                            >Yes</span
                          >
                          <span v-else>No</span>
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6>
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <span class="mainheading">Number Of Animals</span><br />
                      <span class="mainheading">
                        <span>
                          <span v-if="selectedItem.singleOrGroup">
                            <span v-if="selectedItem.singleOrGroup === 'Single'"
                              >1</span
                            >
                            <span v-else>{{ selectedItem.singleOrGroup }}</span>
                          </span>
                          <span v-else>- </span>
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                                <!-- COUNT -->
                                <v-flex xs12>
                  <v-layout wrap justify-start>
                    <!-- Animal Injured Details -->
                    <!-- <v-flex
                      xs12
                      sm6
                      md6
                      lg4
                      v-if="
                        selectedItem &&
                        selectedItem.animalInjuryDetails &&
                        selectedItem.animalInjuryDetails.injuredNumber > 0
                      "
                    >
                      <v-layout wrap justify-start pt-3>
                        <v-flex xs12>
                          <span class="subheading">Animals Injured</span><br />
                          <span>Number Of Injured</span><br />
                          <span class="mainheading">
                            <span v-if="selectedItem.animalInjuryDetails"
                              >{{
                                selectedItem.animalInjuryDetails.injuredNumber
                              }}
                            </span>
                            <span v-else>0</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex> -->
                  </v-layout>
                </v-flex>
                <v-flex xs12 pt-3>
                  <v-layout
                    wrap
                    justify-start
                    v-if="selectedItem.singleOrGroup === 'Single'"
                  >
                    <v-flex xs12>
                      <span class="subheading">Single Animal Details</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-simple-table style="border: solid grey 1px">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left mainheading">Status</th>
                              <th class="text-left mainheading">Gender</th>
                              <th class="text-left mainheading">Age</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="mainheading">
                              <td>
                                <span
                                  v-if="
                                    selectedItem &&
                                    selectedItem.singleDetails.animalStatusId
                                  "
                                  >{{
                                    selectedItem.singleDetails.animalStatusId
                                      .name
                                  }}</span
                                >
                                <span v-else>Unknown</span>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    selectedItem &&
                                    selectedItem.singleDetails.genderId
                                  "
                                  >{{
                                    selectedItem.singleDetails.genderId.name
                                  }}</span
                                >
                                <span v-else>Unknown</span>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    selectedItem &&
                                    selectedItem.singleDetails.animalAge
                                  "
                                  >{{
                                    selectedItem.singleDetails.animalAge
                                  }}</span
                                >
                                <span v-else>Unknown</span>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-flex>
                    <!-- <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12 sm4 md4 lg4>
                          <span class="mainheading">Status</span><br />
                          <span class="mainheading">
                            <span
                              v-if="selectedItem.singleDetails.animalStatusId"
                              >{{
                                selectedItem.singleDetails.animalStatusId.name
                              }}
                            </span>
                            <span v-else>UnKnown</span>
                          </span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <span class="mainheading">Gender</span><br />
                          <span class="mainheading">
                            <span v-if="selectedItem.singleDetails.genderId"
                              >{{ selectedItem.singleDetails.genderId.name }}
                            </span>
                            <span v-else>UnKnown</span>
                          </span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <span class="mainheading">Age</span><br />
                          <span class="mainheading">
                            <span v-if="selectedItem.singleDetails.animalAge"
                              >{{ selectedItem.singleDetails.animalAge }}
                            </span>
                            <span v-else>UnKnown</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex> -->
                  </v-layout>
                  <v-layout
                    wrap
                    justify-start
                    v-if="selectedItem.singleOrGroup === 'Group'"
                  >
                    <v-flex xs12>
                      <span class="subheading">Animal Group Details</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-simple-table style="border: solid grey 1px">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left mainheading">Adult Count</th>
                              <th class="text-left mainheading">
                                Sub-Adult Count
                              </th>
                              <th class="text-left mainheading">Calf Count</th>
                              <th class="text-left mainheading">
                                Juvenile Count
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="mainheading">
                              <td>
                                <span
                                  v-if="
                                    selectedItem &&
                                    selectedItem.groupDetails.adultCount
                                  "
                                  >{{
                                    selectedItem.groupDetails.adultCount
                                  }}</span
                                >
                                <span v-else>0</span>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    selectedItem &&
                                    selectedItem.groupDetails.subAdultCount
                                  "
                                  >{{
                                    selectedItem.groupDetails.subAdultCount
                                  }}</span
                                >
                                <span v-else>0</span>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    selectedItem &&
                                    selectedItem.groupDetails.calfCount
                                  "
                                  >{{
                                    selectedItem.groupDetails.calfCount
                                  }}</span
                                >
                                <span v-else>0</span>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    selectedItem &&
                                    selectedItem.groupDetails.juvenileCount
                                  "
                                  >{{
                                    selectedItem.groupDetails.juvenileCount
                                  }}</span
                                >
                                <span v-else>0</span>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-flex>
                    <v-flex xs12 pt-2>
                      <v-simple-table style="border: solid grey 1px">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left mainheading">Male Count</th>
                              <th class="text-left mainheading">
                                Female Count
                              </th>
                              <th class="text-left mainheading">
                                Unidentified
                              </th>
                              <th class="text-left mainheading">
                                Animal Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="mainheading">
                              <td>
                                <span
                                  v-if="
                                    selectedItem &&
                                    selectedItem.groupDetails.maleCount
                                  "
                                  >{{
                                    selectedItem.groupDetails.maleCount
                                  }}</span
                                >
                                <span v-else>0</span>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    selectedItem &&
                                    selectedItem.groupDetails.femaleCount
                                  "
                                  >{{
                                    selectedItem.groupDetails.femaleCount
                                  }}</span
                                >
                                <span v-else>0</span>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    selectedItem &&
                                    selectedItem.groupDetails
                                      .cannotDetermineCount
                                  "
                                  >{{
                                    selectedItem.groupDetails
                                      .cannotDetermineCount
                                  }}</span
                                >
                                <span v-else>0</span>
                              </td>
                              <td>
                                <span
                                  v-if="
                                    selectedItem &&
                                    selectedItem.groupDetails.animalStatus
                                  "
                                  >{{
                                    selectedItem.groupDetails.animalStatus
                                  }}</span
                                >
                                <span v-else>0</span>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-flex>
                    <!-- <v-flex xs12>
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm3 md3 lg3>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span
                                style="
                                  font-family: sofiaProLight;
                                  font-size: 13px;
                                  color: #4d4d4d;
                                "
                                >Adult Count</span
                              ><br />
                              <span class="mainheading">
                                <span
                                  v-if="selectedItem.groupDetails.adultCount"
                                  >{{ selectedItem.groupDetails.adultCount }}
                                </span>
                                <span v-else>0</span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg3>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span
                                style="
                                  font-family: sofiaProLight;
                                  font-size: 13px;
                                  color: #4d4d4d;
                                "
                                >Sub-Adult Count</span
                              ><br />
                              <span class="mainheading">
                                <span
                                  v-if="selectedItem.groupDetails.subAdultCount"
                                  >{{ selectedItem.groupDetails.subAdultCount }}
                                </span>
                                <span v-else>0</span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg3>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span
                                style="
                                  font-family: sofiaProLight;
                                  font-size: 13px;
                                  color: #4d4d4d;
                                "
                                >Calf Count</span
                              ><br />
                              <span class="mainheading">
                                <span v-if="selectedItem.groupDetails.calfCount"
                                  >{{ selectedItem.groupDetails.calfCount }}
                                </span>
                                <span v-else>0</span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg3>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span
                                style="
                                  font-family: sofiaProLight;
                                  font-size: 13px;
                                  color: #4d4d4d;
                                "
                                >Juvenile Count</span
                              ><br />
                              <span class="mainheading">
                                <span
                                  v-if="selectedItem.groupDetails.juvenileCount"
                                  >{{ selectedItem.groupDetails.juvenileCount }}
                                </span>
                                <span v-else>0</span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex> -->
                    <!-- <v-flex xs12>
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm3 md3 lg3>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span
                                style="
                                  font-family: sofiaProLight;
                                  font-size: 13px;
                                  color: #4d4d4d;
                                "
                                >Male Count</span
                              ><br />
                              <span class="mainheading">
                                <span v-if="selectedItem.groupDetails.maleCount"
                                  >{{ selectedItem.groupDetails.maleCount }}
                                </span>
                                <span v-else>0</span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg3>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span
                                style="
                                  font-family: sofiaProLight;
                                  font-size: 13px;
                                  color: #4d4d4d;
                                "
                                >Female Count</span
                              ><br />
                              <span class="mainheading">
                                <span
                                  v-if="selectedItem.groupDetails.femaleCount"
                                  >{{ selectedItem.groupDetails.femaleCount }}
                                </span>
                                <span v-else>0</span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg3>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span
                                style="
                                  font-family: sofiaProLight;
                                  font-size: 13px;
                                  color: #4d4d4d;
                                "
                                >Unidentified</span
                              ><br />
                              <span class="mainheading">
                                <span
                                  v-if="
                                    selectedItem.groupDetails
                                      .cannotDetermineCount
                                  "
                                  >{{
                                    selectedItem.groupDetails
                                      .cannotDetermineCount
                                  }}
                                </span>
                                <span v-else>0</span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg3>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span
                                style="
                                  font-family: sofiaProLight;
                                  font-size: 13px;
                                  color: #4d4d4d;
                                "
                                >Animal Status</span
                              ><br />
                              <span class="mainheading">
                                <span
                                  v-if="selectedItem.groupDetails.animalStatus"
                                  >{{ selectedItem.groupDetails.animalStatus }}
                                </span>
                                <span v-else>0</span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex> -->
                  </v-layout>
                </v-flex>
              </v-layout>
              <!-- Conflict Details -->
              <v-layout wrap justify-start pt-3>
                <!-- Animal Injury Details -->
                <v-flex xs12 v-if="selectedItem.isAnimalInjured">
                  <v-layout
                    wrap
                    justify-center
                    v-if="selectedItem.isAnimalInjured"
                  >
                    <v-flex xs12 v-if="selectedItem.isAnimalInjured">
                      <v-layout wrap justify-start>
                        <v-flex xs12>
                          <span class="subheading">Animal Injury Details</span>
                        </v-flex>
                      </v-layout>
                      <!-- <v-layout wrap justify-start>
                        <v-flex xs12 sm4 md4 lg4>
                          <span class="mainheading">Number Of Injured</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <span class="mainheading">Type of injury</span>
                        </v-flex>
                      </v-layout> -->
                      <!-- <v-layout class="text-left">
                        <v-flex
                          xs12
                          sm4
                          md4
                          lg4
                          v-if="selectedItem.animalInjuryDetails"
                        >
                          <span
                            class="mainheading"
                            v-if="
                              selectedItem.animalInjuryDetails.injuredNumber
                            "
                            >{{
                              selectedItem.animalInjuryDetails.injuredNumber
                            }}</span
                          >
                          <span class="mainheading" v-else>Unknown</span>
                        </v-flex>
                        <v-flex
                          xs12
                          sm4
                          md4
                          lg4
                          v-if="selectedItem.animalInjuryDetails"
                        >
                          <span
                            class="mainheading"
                            v-if="selectedItem.animalInjuryDetails.typeOfInjury"
                            >{{
                              selectedItem.animalInjuryDetails.typeOfInjury
                            }}</span
                          >
                          <span class="mainheading" v-else>Unknown</span>
                        </v-flex>
                      </v-layout> -->
                      <v-flex xs12>
                        <v-simple-table style="border: solid grey 1px">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left mainheading">
                                  Count
                                </th>
                                <th class="text-left mainheading">
                                  Type of injury
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="mainheading">
                                <td>
                                  <span
                                    v-if="
                                      selectedItem &&
                                      selectedItem.animalInjuryDetails.injuredNumber
                                    "
                                    >{{
                                      selectedItem.animalInjuryDetails.injuredNumber
                                    }}</span
                                  >
                                  <span v-else>0</span>
                                </td>
                                <td>
                                  <span
                                    v-if="
                                      selectedItem &&
                                      selectedItem.animalInjuryDetails.typeOfInjury
                                    "
                                    >{{
                                      selectedItem.animalInjuryDetails.typeOfInjury
                                    }}</span
                                  >
                                  <span v-else>Unknown</span>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <!-- Crop Damage Details -->
                <v-flex xs12 v-if="selectedItem.isCropDamage">
                  <v-layout
                    wrap
                    justify-center
                    v-if="selectedItem.isCropDamage"
                    pt-3
                  >
                    <v-flex xs12 v-if="selectedItem.cropDamageDetails">
                      <v-layout wrap justify-start>
                        <v-flex xs12>
                          <span class="subheading">Crop Damage Details</span>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap justify-start>
                        <v-flex xs12>
                          <v-simple-table style="border: solid grey 1px">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left mainheading">
                                  Sl No.
                                </th>
                                <th class="text-left mainheading">
                                  Crop Type
                                </th>
                                <th class="text-left mainheading">
                                  Extend/Number Damaged
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="mainheading" v-for="(item, i) in selectedItem.cropDamageDetails"
                              :key="i">
                                <td>
                                  {{ i + 1 }}
                                </td>
                                <td>
                                  <span v-if="item.cropId.name">{{item.cropId.name}}</span>
                                  <span v-else>Unknown</span>
                                </td>
                                <td>
                                  <span v-if="item.count">
                                    {{ item.count }} {{ item.unit }}
                                  </span>
                                  <span v-else>Unknown</span>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        </v-flex>
                      </v-layout>
                      <!-- <v-layout wrap justify-start>
                        <v-flex xs12 sm1 md1 lg1>
                          <span class="mainheading">Sl No.</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg3>
                          <span class="mainheading">Crop Type</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <span class="mainheading">Extend/Number Damaged</span>
                        </v-flex>
                      </v-layout> -->
                      <!-- <v-layout
                        v-for="(item, i) in selectedItem.cropDamageDetails"
                        :key="i"
                        class="text-left"
                      >
                        <v-flex xs12 sm1 md1 lg1>
                          <span class="mainheading">{{ i + 1 }}</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg3>
                          <span class="mainheading" v-if="item.cropId.name">{{
                            item.cropId.name
                          }}</span>
                          <span class="mainheading" v-else>Unknown</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <span class="mainheading"
                            >{{ item.count }} {{ item.unit }}</span
                          >
                        </v-flex>
                      </v-layout> -->
                    </v-flex>
                  </v-layout>
                </v-flex>
                <!-- Property Damage Details -->
                <v-flex xs12 v-if="selectedItem.isPropertyDamage">
                  <v-layout
                    wrap
                    justify-center
                    v-if="selectedItem.isPropertyDamage"
                    pt-3
                  >
                    <v-flex xs12 v-if="selectedItem.propertyDamageDetails">
                      <v-layout wrap justify-start>
                        <v-flex xs12>
                          <span class="subheading"
                            >Property Damage Details</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout wrap justify-start>
                        <v-flex xs12>
                          <v-simple-table style="border: solid grey 1px">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left mainheading">
                                  Sl No.
                                </th>
                                <th class="text-left mainheading">
                                  Property Type
                                </th>
                                <th class="text-left mainheading">
                                  Damage Detail
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="mainheading" v-for="(item, i) in selectedItem.propertyDamageDetails"
                              :key="i">
                                <td>
                                  {{ i + 1 }}
                                </td>
                                <td>
                                  <span v-if="item.propertyType">{{item.propertyType}}</span>
                                  <span v-else>Unknown</span>
                                </td>
                                <td>
                                  <span v-if="item.damageDetail">{{ item.damageDetail }}</span>
                                  <span v-else>Unknown</span>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        </v-flex>
                      </v-layout>
                      <!-- <v-layout wrap justify-start>
                        <v-flex xs12 sm1 md1 lg1>
                          <span class="mainheading">Sl.No</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg3>
                          <span class="mainheading">Property Type</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <span class="mainheading">Damage Detail</span>
                        </v-flex>
                      </v-layout> -->
                      <!-- <v-layout
                        v-for="(item, i) in selectedItem.propertyDamageDetails"
                        :key="i"
                        class="text-left"
                      >
                        <v-flex xs12 sm1 md1 lg1>
                          <span class="mainheading">{{ i + 1 }}</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg3>
                          <span class="mainheading" v-if="item.propertyType">{{
                            item.propertyType
                          }}</span>
                          <span class="mainheading" v-else>Unknown</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <span class="mainheading" v-if="item.damageDetail">{{
                            item.damageDetail
                          }}</span>
                          <span class="mainheading" v-else>Unknown</span>
                        </v-flex>
                      </v-layout> -->
                    </v-flex>
                  </v-layout>
                </v-flex>
                <!-- Human Injury Details -->
                <v-flex xs12 v-if="selectedItem.isHumanInjured">
                  <v-layout
                    wrap
                    justify-center
                    v-if="selectedItem.isHumanInjured"
                    pt-3
                  >
                    <v-flex xs12 v-if="selectedItem.humanInjuryDetails">
                      <v-layout wrap justify-start>
                        <v-flex xs12>
                          <span class="subheading">Human Injury Details</span>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap justify-start>
                        <v-flex xs12>
                          <v-simple-table style="border: solid grey 1px">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left mainheading">
                                  Sl No.
                                </th>
                                <th class="text-left mainheading">
                                  Name
                                </th>
                                <th class="text-left mainheading">
                                  Age
                                </th>
                                <th class="text-left mainheading">
                                  Gender
                                </th>
                                <th class="text-left mainheading">
                                  Injury Type
                                </th>
                                <th class="text-left mainheading">
                                  Hospitalized Or Not?
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="mainheading" v-for="(item, i) in selectedItem.humanInjuryDetails.personDetails"
                              :key="i">
                                <td>
                                  {{ i + 1 }}
                                </td>
                                <td>
                                  <span v-if="item.name">{{item.name}}</span>
                                  <span v-else>Unknown</span>
                                </td>
                                <td>
                                  <span v-if="item.age">{{ item.age }}</span>
                                  <span v-else>Unknown</span>
                                  </td>
                                <td>
                                  <span v-if="item.gender">{{ item.gender }}</span>
                                  <span v-else>Unknown</span>
                                  </td>
                                <td>
                                  <span v-if="item.typeOfInjury">{{ item.typeOfInjury }}</span>
                                  <span v-else>Unknown</span>
                                  </td>
                                <td>
                                  <span v-if="item.isHospitalized === true">Yes</span>
                                  <span v-else>No</span>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        </v-flex>
                      </v-layout>
                      <!-- <v-layout wrap justify-start>
                        <v-flex xs12 sm1 md1 lg1>
                          <span class="mainheading">Sl.No</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg2>
                          <span class="mainheading">Name</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg2>
                          <span class="mainheading">Age</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg2>
                          <span class="mainheading">Gender</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg2>
                          <span class="mainheading">Injury Type</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg3>
                          <span class="mainheading">Hospitalized Or Not?</span>
                        </v-flex>
                      </v-layout> -->
                      <!-- <v-layout
                        v-for="(item, i) in selectedItem.humanInjuryDetails
                          .personDetails"
                        :key="i"
                        class="text-left"
                      >
                        <v-flex xs12 sm1 md1 lg1>
                          <span class="mainheading">{{ i + 1 }}</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg2>
                          <span class="mainheading" v-if="item.name">{{
                            item.name
                          }}</span>
                          <span class="mainheading" v-else>Unknown</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg2>
                          <span class="mainheading" v-if="item.age">{{
                            item.age
                          }}</span>
                          <span class="mainheading" v-else>Unknown</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg2>
                          <span class="mainheading" v-if="item.gender">{{
                            item.gender
                          }}</span>
                          <span class="mainheading" v-else>Unknown</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg2>
                          <span class="mainheading" v-if="item.typeOfInjury">{{
                            item.typeOfInjury
                          }}</span>
                          <span class="mainheading" v-else>Unknown</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg3>
                          <span class="mainheading">
                            <span v-if="item.isHospitalized === true">Yes</span>
                            <span v-else>No</span>
                          </span>
                        </v-flex>
                      </v-layout> -->
                    </v-flex>
                  </v-layout>
                </v-flex>
                <!-- Human Death Details -->
                <v-flex xs12 v-if="selectedItem.isHumanDeath">
                  <v-layout
                    wrap
                    justify-center
                    v-if="selectedItem.isHumanDeath"
                    pt-3
                  >
                    <v-flex xs12 v-if="selectedItem.humanDeathDetails">
                      <v-layout wrap justify-start>
                        <v-flex xs12>
                          <span class="subheading">Human Death Details</span>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap justify-start>
                        <v-flex xs12>
                          <v-simple-table style="border: solid grey 1px">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left mainheading">
                                  Sl No.
                                </th>
                                <th class="text-left mainheading">
                                  Name
                                </th>
                                <th class="text-left mainheading">
                                  Age
                                </th>
                                <th class="text-left mainheading">
                                  Gender
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="mainheading" v-for="(item, i) in selectedItem.humanDeathDetails.personDetails"
                              :key="i">
                                <td>
                                  {{ i + 1 }}
                                </td>
                                <td>
                                  <span v-if="item.name">{{item.name}}</span>
                                  <span v-else>Unknown</span>
                                </td>
                                <td>
                                  <span v-if="item.age">{{ item.age }}</span>
                                  <span v-else>Unknown</span>
                                  </td>
                                <td>
                                  <span v-if="item.gender">{{ item.gender }}</span>
                                  <span v-else>Unknown</span>
                                  </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        </v-flex>
                      </v-layout>
                      <!-- <v-layout wrap justify-start>
                        <v-flex xs12 sm3 md3 lg1>
                          <span class="mainheading">Sl.No</span>
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg2>
                          <span class="mainheading">Name</span>
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg2>
                          <span class="mainheading">Age</span>
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg3>
                          <span class="mainheading">Gender</span>
                        </v-flex>
                      </v-layout> -->
                      <!-- <v-layout
                        v-for="(item, i) in selectedItem.humanDeathDetails
                          .personDetails"
                        :key="i"
                        class="text-left"
                      >
                        <v-flex xs12 sm3 md3 lg1>
                          <span class="mainheading">{{ i + 1 }}</span>
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg2>
                          <span class="mainheading" v-if="item.name">{{
                            item.name
                          }}</span>
                          <span class="mainheading" v-else>Unknown</span>
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg2>
                          <span class="mainheading" v-if="item.age">{{
                            item.age
                          }}</span>
                          <span class="mainheading" v-else>Unknown</span>
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg3>
                          <span class="mainheading" v-if="item.gender">{{
                            item.gender
                          }}</span>
                          <span class="mainheading" v-else>Unknown</span>
                        </v-flex>
                      </v-layout> -->
                    </v-flex>
                  </v-layout>
                </v-flex>
                <!-- Livestock Animal Depredation Details -->
                <v-flex xs12 v-if="selectedItem.isLivestockDepredation">
                  <v-layout
                    wrap
                    justify-center
                    v-if="selectedItem.isLivestockDepredation"
                    pt-3
                  >
                    <v-flex xs12 v-if="selectedItem.isLivestockDepredation">
                      <v-layout wrap justify-start>
                        <v-flex xs12>
                          <span class="subheading"
                            >Livestock Animal Depredation Details</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout wrap justify-start>
                        <v-flex xs12>
                          <v-simple-table style="border: solid grey 1px">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left mainheading">
                                  Sl No.
                                </th>
                                <th class="text-left mainheading">
                                  Count
                                </th>
                                <th class="text-left mainheading">
                                  Animal Type
                                </th>
                                <th class="text-left mainheading">
                                  Injury/Death
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="mainheading" v-for="(item, i) in selectedItem.livestockDepredationDetails"
                              :key="i">
                                <td>
                                  {{ i + 1 }}
                                </td>
                                <td>
                                  <span v-if="item.numberOfAnimals">{{item.numberOfAnimals}}</span>
                                  <span v-else>0</span>
                                </td>
                                <td>
                                  <span v-if="item.typeOfAnimal">{{ item.typeOfAnimal }}</span>
                                  <span v-else>Unknown</span>
                                  </td>
                                <td>
                                  <span v-if="item.injuryOrDeath">{{ item.injuryOrDeath }}</span>
                                  <span v-else>Unknown</span>
                                  </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        </v-flex>
                      </v-layout>
                      <!-- <v-layout wrap justify-start>
                        <v-flex xs12 sm1 md1 lg1>
                          <span class="mainheading">Sl.No</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <span class="mainheading">Animal Type</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <span class="mainheading">Injury/Death</span>
                        </v-flex>
                      </v-layout> -->
                      <!-- <v-layout
                        v-for="(
                          item, i
                        ) in selectedItem.livestockDepredationDetails"
                        :key="i"
                        class="text-left"
                      >
                        <v-flex xs12 sm1 md1 lg1>
                          <span class="mainheading">{{ i + 1 }}</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <span class="mainheading" v-if="item.typeOfAnimal">{{
                            item.typeOfAnimal
                          }}</span>
                          <span class="mainheading" v-else>Unknown</span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <span class="mainheading" v-if="item.injuryOrDeath">{{
                            item.injuryOrDeath
                          }}</span>
                          <span class="mainheading" v-else>Unknown</span>
                        </v-flex>
                      </v-layout> -->
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <!-- Land Details -->
              <v-layout wrap justify-start pt-3>
                <!-- Animal Death Details -->
                <v-flex
                      v-if="
                        selectedItem &&
                        selectedItem.animalDeathDetails &&
                        selectedItem.animalDeathDetails.numberOfDied > 0
                      "
                    >
                      <v-layout wrap justify-start>
                        <v-flex xs12>
                          <span class="subheading">Animals Dead</span><br />
                          <span>Count</span><br />
                          <span class="mainheading">
                            <span v-if="selectedItem.animalDeathDetails"
                              >{{
                                selectedItem.animalDeathDetails.numberOfDied
                              }}
                            </span>
                            <span v-else>0</span>
                          </span>
                        </v-flex>
                      </v-layout>
                </v-flex>
                <v-flex>
                  <span class="subheading">Land Details</span><br />
                  <span class="mainheading">Land Type</span><br />
                  <span class="mainheading">
                    <span v-if="selectedItem.landTypeId"
                      >{{ selectedItem.landTypeId.name }}
                    </span>
                    <span v-else>UnKnown</span>
                  </span>
                </v-flex>
              </v-layout>
              <!-- Barrier Details v-if="selectedItem.isBreachedBarrier" -->
              <v-layout
                wrap
                justify-start
                pt-3
                v-if="
                  (selectedItem && selectedItem.barrierTypeId.length > 0) ||
                  (selectedItem && selectedItem.barrierTypeId.length > 0)
                "
              >
                <v-flex xs12
                  ><span class="subheading">Barrier Details</span></v-flex
                >
                <v-flex
                  xs12
                  sm6
                  md6
                  lg6
                  v-if="selectedItem && selectedItem.barrierTypeId.length > 0"
                >
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <span class="mainheading">Barrier Type</span><br />
                      <span class="mainheading">
                        <span
                          v-for="(item, i) in selectedItem.barrierTypeId"
                          :key="i"
                          ><span v-if="item.name"
                            >{{ item.name }}
                            <span
                              v-if="i < selectedItem.barrierTypeId.length - 1"
                              >,
                            </span></span
                          >
                          <span v-else>UnKnown</span>
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  md6
                  lg6
                  v-if="selectedItem && selectedItem.breachTypeId.length > 0"
                >
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <span class="mainheading">Breach Type</span><br />
                      <span class="mainheading">
                        <span
                          v-for="(item, i) in selectedItem.breachTypeId"
                          :key="i"
                          ><span v-if="item.name"
                            >{{ item.name
                            }}<span
                              v-if="i < selectedItem.breachTypeId.length - 1"
                              >,
                            </span></span
                          >
                          <span v-else>UnKnown</span>
                        </span>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <!-- Resolved & Released Details -->
              <v-layout wrap justify-start pt-3>
                <v-flex xs12>
                  <span class="subheading">Resolved & Released Details</span>
                </v-flex>
                <v-flex xs12 v-if="selectedItem.isResolved">
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12 sm4 md4 lg4>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="mainheading"
                                >Whether resolved or not?</span
                              ><br />
                              <span class="mainheading">
                                <span>
                                  <span v-if="selectedItem.isResolved"
                                    >Yes</span
                                  >
                                  <span v-else>No </span>
                                </span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex
                          xs12
                          sm4
                          md4
                          lg4
                          v-if="selectedItem.mitigationTypeId"
                        >
                          <span class="mainheading">Mitigation Type</span><br />
                          <span class="mainheading">
                            <span v-if="selectedItem.mitigationTypeId"
                              >{{ selectedItem.mitigationTypeId.name }}
                            </span>
                            <span v-else>Nil</span>
                          </span>
                        </v-flex>
                        <v-flex
                          xs12
                          sm4
                          md4
                          lg4
                          v-if="selectedItem.howFarintoForest"
                        >
                          <span class="mainheading">How far into forest?</span
                          ><br />
                          <span class="mainheading">
                            <span v-if="selectedItem.howFarintoForest"
                              >{{ selectedItem.howFarintoForest }}
                            </span>
                            <span v-else>Nil</span>
                          </span>
                        </v-flex>
                        <v-flex
                          xs12
                          sm4
                          md4
                          lg4
                          v-if="selectedItem.mitigationMethodId"
                        >
                          <span class="mainheading">Mitigation Method</span
                          ><br />
                          <span class="mainheading">
                            <span v-if="selectedItem.mitigationMethodId"
                              >{{ selectedItem.mitigationMethodId.name }}
                            </span>
                            <span v-else>Nil</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-3>
                      <v-layout wrap justify-start>
                        <v-flex xs12 sm4 md4 lg4>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="mainheading"
                                >Whether released or not?</span
                              ><br />
                              <span class="mainheading">
                                <span>
                                  <span v-if="selectedItem.isReleased"
                                    >Yes</span
                                  >
                                  <span v-else>No </span>
                                </span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex
                          xs12
                          sm4
                          md4
                          lg4
                          v-if="
                            selectedItem.isTreatment || selectedItem.isPermanent
                          "
                        >
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="mainheading">Why not released?</span
                              ><br />
                              <span class="mainheading">
                                <span>
                                  <span v-if="selectedItem.isTreatment"
                                    >Treatment</span
                                  >
                                  <span v-else>Permanent</span>
                                </span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <span class="mainheading">Resolved Location</span
                          ><br />
                          <span class="mainheading">
                            <span v-if="selectedItem.resolvedLocationName"
                              >{{ selectedItem.resolvedLocationName }}
                            </span>
                            <span v-else>Nil</span>
                          </span>
                        </v-flex>
                        <!-- <v-flex xs12 sm4 md4 lg4>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="mainheading"
                                >Veterinary present or not?</span
                              ><br />
                              <span class="mainheading">
                                <span>
                                  <span v-if="selectedItem.isVeterinaryPresent"
                                    >Yes</span
                                  >
                                  <span v-else>No </span>
                                </span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex> -->
                        <!-- <v-flex xs12>
                          <span class="mainheading">Resolved Location</span><br />
                          <span class="mainheading">
                            <span v-if="selectedItem.resolvedLocationName"
                              >{{ selectedItem.resolvedLocationName }}
                            </span>
                            <span v-else>Nil</span>
                          </span>
                        </v-flex> -->
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 v-if="selectedItem.isResolved === false">
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12 sm4 md4 lg4>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="mainheading"
                                >Whether resolved or not?</span
                              ><br />
                              <span class="mainheading">
                                <span>
                                  <span v-if="selectedItem.isResolved"
                                    >Yes</span
                                  >
                                  <span v-else>No </span>
                                </span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <span class="mainheading">If not resolved?</span
                          ><br />
                          <span class="mainheading">
                            <span v-if="selectedItem.isStepsForMitigation"
                              >Steps For Mitigation
                            </span>
                            <span v-else>Handing Over</span>
                          </span>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4 v-if="selectedItem.isHandover">
                          <span class="mainheading">Handing over to?</span
                          ><br />
                          <span class="mainheading">
                            <span v-if="selectedItem.handoverDetails">{{
                              selectedItem.handoverDetails.handoverplatform
                            }}</span>
                            <span v-else>UnKnown </span>
                          </span>
                        </v-flex>
                        <v-flex
                          xs12
                          sm4
                          md4
                          lg4
                          v-if="
                            selectedItem.isHandover &&
                            selectedItem.handoverDetails &&
                            selectedItem.handoverDetails.handoverplatform
                          "
                        >
                          <span class="mainmainhead"
                            >{{
                              selectedItem.handoverDetails.handoverplatform
                            }}
                            Name</span
                          ><br />
                          <span class="mainmainhead">
                            <span
                              v-if="
                                selectedItem.handoverDetails.handoverplatform ==
                                  'Division' &&
                                selectedItem.handoverDetails.divisionId
                              "
                              >{{
                                selectedItem.handoverDetails.divisionId
                                  .divisionName
                              }}</span
                            >
                            <span
                              v-else-if="
                                selectedItem.handoverDetails.handoverplatform ==
                                  'Range' &&
                                selectedItem.handoverDetails.rangeId
                              "
                              >{{
                                selectedItem.handoverDetails.rangeId.rangeName
                              }}</span
                            >
                            <span
                              v-else-if="
                                selectedItem.handoverDetails.handoverplatform ==
                                  'RRT' && selectedItem.handoverDetails.RRT
                              "
                              >{{ selectedItem.handoverDetails.RRT.name }}</span
                            >
                            <span v-else>UnKnown</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout
                        wrap
                        justify-center
                        v-if="selectedItem.isStepsForMitigation"
                        pt-3
                      >
                        <v-flex xs12 v-if="selectedItem.isStepsForMitigation">
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="subheading">Mitigation Steps</span>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start>
                            <v-flex xs12 sm1 md1 lg1>
                              <span class="mainheading">Sl.No</span>
                            </v-flex>
                            <v-flex xs12 sm4 md4 lg4>
                              <span class="mainheading">Mitigation Type</span>
                            </v-flex>
                            <v-flex xs12 sm4 md4 lg4>
                              <span class="mainheading">Start Date</span>
                            </v-flex>
                          </v-layout>
                          <v-layout
                            v-for="(item, i) in selectedItem.stepsForMitigation"
                            :key="i"
                            class="text-left"
                          >
                            <v-flex xs12 sm1 md1 lg1>
                              <span class="mainheading">{{ i + 1 }}</span>
                            </v-flex>
                            <v-flex xs12 sm4 md4 lg4>
                              <span class="mainheading" v-if="item.name">{{
                                item.name
                              }}</span>
                              <span class="mainheading" v-else>Unknown</span>
                            </v-flex>
                            <v-flex xs12 sm4 md4 lg4>
                              <span
                                class="mainheading"
                                v-if="item.mitigationStartDate"
                                >{{
                                  formatDate(item.mitigationStartDate)
                                }}</span
                              >
                              <span class="mainheading" v-else>Unknown</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <!-- Treatment Details -->
                <v-flex xs12 pt-3 v-if="selectedItem.isTreatment">
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <span class="subheading">Treatment Details</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12 sm4 md4 lg4>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="mainheading">Treatment Place</span
                              ><br />
                              <span class="mainheading">
                                <span>
                                  <span
                                    v-if="
                                      selectedItem.treatmentDetails &&
                                      selectedItem.treatmentDetails
                                        .treatmentPlace
                                    "
                                    >{{
                                      selectedItem.treatmentDetails
                                        .treatmentPlace
                                    }}</span
                                  >
                                  <span v-else>-</span>
                                </span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="mainheading">Date</span><br />
                              <span class="mainheading">
                                <span>
                                  <span
                                    v-if="
                                      selectedItem.treatmentDetails &&
                                      selectedItem.treatmentDetails
                                        .treatmentDate
                                    "
                                    >{{
                                      formatDate(
                                        selectedItem.treatmentDetails
                                          .treatmentDate
                                      )
                                    }}</span
                                  >
                                  <span v-else>-</span>
                                </span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="mainheading">Time</span><br />
                              <span class="mainheading">
                                <span>
                                  <span
                                    v-if="
                                      selectedItem.treatmentDetails &&
                                      selectedItem.treatmentDetails
                                        .treatmentTime
                                    "
                                    >{{
                                      formatTime24To12(
                                        selectedItem.treatmentDetails
                                          .treatmentTime
                                      )
                                    }}</span
                                  >
                                  <span v-else>-</span>
                                </span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 pt-3>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="mainheading">Reason</span><br />
                              <span class="mainheading">
                                <span>
                                  <span
                                    v-if="
                                      selectedItem.treatmentDetails &&
                                      selectedItem.treatmentDetails.reason
                                    "
                                    >{{
                                      selectedItem.treatmentDetails.reason
                                    }}</span
                                  >
                                  <span v-else>-</span>
                                </span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <!-- Permenant Details -->
                <v-flex xs12 pt-3 v-if="selectedItem.isPermanent">
                  <v-layout wrap justify-start>
                    <v-flex xs12>
                      <span class="subheading">Permanent Details</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12 sm4 md4 lg4>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="mainheading">Permanent Place</span
                              ><br />
                              <span class="mainheading">
                                <span>
                                  <span
                                    v-if="
                                      selectedItem.permanentDetails &&
                                      selectedItem.permanentDetails
                                        .permanentPlace
                                    "
                                    >{{
                                      selectedItem.permanentDetails
                                        .permanentPlace
                                    }}</span
                                  >
                                  <span v-else>-</span>
                                </span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="mainheading">Date</span><br />
                              <span class="mainheading">
                                <span>
                                  <span
                                    v-if="
                                      selectedItem.permanentDetails &&
                                      selectedItem.permanentDetails
                                        .permanentDate
                                    "
                                    >{{
                                      formatDate(
                                        selectedItem.permanentDetails
                                          .permanentDate
                                      )
                                    }}</span
                                  >
                                  <span v-else>-</span>
                                </span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm4 md4 lg4>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="mainheading">Time</span><br />
                              <span class="mainheading">
                                <span>
                                  <span
                                    v-if="
                                      selectedItem.permanentDetails &&
                                      selectedItem.permanentDetails
                                        .permanentTime
                                    "
                                    >{{
                                      formatTime24To12(
                                        selectedItem.permanentDetails
                                          .permanentTime
                                      )
                                    }}</span
                                  >
                                  <span v-else>-</span>
                                </span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 pt-3>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <span class="mainheading">Reason</span><br />
                              <span class="mainheading">
                                <span>
                                  <span
                                    v-if="
                                      selectedItem.permanentDetails &&
                                      selectedItem.permanentDetails.reason
                                    "
                                    >{{
                                      selectedItem.permanentDetails.reason
                                    }}</span
                                  >
                                  <span v-else>-</span>
                                </span>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-layout
                wrap
                justify-center
                v-if="selectedItem && selectedItem.resolveImages.length > 0"
                pt-3
              >
                <!-- <v-flex xs12>
                  <span class="subheading">Resolved Images</span>
                </v-flex> -->
                <!-- <v-flex xs12 v-if="selectedItem.resolveImages.length > 0">
                  <v-layout wrap justify-center>
                    <v-flex
                      xs12
                      sm4
                      md4
                      lg4
                      v-for="(item, i) in selectedItem.resolveImages"
                      :key="i"
                      pa-2
                    >
                      <div v-viewer style="display: flex">
                        <img
                          :src="mediaURL + item"
                          style="cursor: pointer"
                          width="150px"
                          height="150px"
                        />
                      </div>
                    </v-flex>
                  </v-layout>

                  <v-dialog v-model="dialog1" max-width="800">
                    <v-card>
                      <v-img :src="selectedImage" max-width="100%"></v-img>
                    </v-card>
                  </v-dialog>
                </v-flex> -->
                <!-- <v-flex
                  xs12
                  v-if="
                    selectedItem &&
                    selectedItem.resolveImages &&
                    selectedItem.resolveImages.length > 0
                  "
                >
                  <OwlCarousel
                    :nav="false"
                    :dots="true"
                    :responsive="owlResponsive"
                  >
                    <template v-for="(item, i) in selectedItem.resolveImages">
                      <CarouselImage
                        :key="i"
                        v-if="item"
                        v-bind:storage="item"
                      />
                    </template>
                  </OwlCarousel>
                </v-flex> -->
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            class="itemValue"
            @click="dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updatemitigationdialog" max-width="800px">
      <v-card>
        <v-card-title class="itemHeading"
          >Update Resolved & Released Details</v-card-title
        >
        <v-card-text>
          <v-layout wrap justify-start>
            <v-flex xs12 sm6 md6 lg6>
              <v-select
                :items="resolvedornotType"
                label="Whether resolved or not?"
                outlined
                v-model="resolvedornot"
                class="itemform"
                dense
              ></v-select>
            </v-flex>
            <v-flex
              xs12
              sm6
              md6
              lg6
              pl-lg-2
              pl-md-2
              pl-sm-2
              v-if="resolvedornot === 'Yes'"
            >
              <span>
                <v-select
                  :items="MitigationTypes"
                  label="Mitigation Types"
                  outlined
                  item-text="name"
                  item-value="_id"
                  v-model="mitigation"
                  class="itemform"
                  dense
                ></v-select>
              </span>
            </v-flex>
            <v-flex
              xs12
              sm6
              md6
              lg6
              v-if="resolvedornot === 'No'"
              pl-lg-2
              pl-md-2
              pl-sm-2
            >
              <span>
                <v-select
                  :items="IfnotResolvedTypes"
                  label="If Not Resolved?"
                  outlined
                  v-model="ifnotresolved"
                  class="itemform"
                  dense
                ></v-select>
              </span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center v-if="resolvedornot === 'Yes'">
            <v-flex xs12>
              <v-layout
                wrap
                justify-center
                v-if="MitigationmethodTypes.length > 0"
              >
                <v-flex xs12 md6 sm6 lg6>
                  <v-select
                    :items="MitigationmethodTypes"
                    label="Mitigation Methods"
                    outlined
                    v-model="mitigationmethods"
                    item-text="name"
                    item-value="_id"
                    class="itemform"
                    dense
                  ></v-select>
                </v-flex>
                <v-flex xs12 md6 sm6 lg6 pl-lg-2 pl-md-2 pl-sm-2>
                  <v-select
                    :items="ReleasedornotTypes"
                    label="Whether released or not?"
                    outlined
                    v-model="releasedornot"
                    class="itemform"
                    dense
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-start v-if="releasedornot === 'No'">
                <v-flex xs12>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm4 md4 lg4>
                      <v-select
                        :items="whynotreleasedTypes"
                        label="Why not released?"
                        outlined
                        v-model="whynotreleased"
                        class="itemform"
                        dense
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                      <v-text-field
                        outlined
                        label="Location"
                        placeholder="Enter The Location"
                        v-model="locationnamenotresolved"
                        class="itemform"
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm2 md2 lg2 pl-lg-2 pl-md-2 pl-sm-2>
                      <v-menu
                        ref="menu5"
                        v-model="menu5"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateofcapture"
                            label="Date"
                            readonly
                            v-bind="attrs"
                            outlined
                            v-on="on"
                            class="itemform"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dateofcapture"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu5 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 sm2 md2 lg2 pl-lg-2 pl-md-2 pl-sm-2>
                      <v-menu
                        ref="menu"
                        v-model="menu4"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="timeofcapture"
                            label="Time"
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="itemform"
                            dense
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu4"
                          v-model="timeofcapture"
                          full-width
                          @click:minute="$refs.menu.save(time)"
                        ></v-time-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap justify-center>
                    <v-flex xs12>
                      <v-textarea
                        outlined
                        name="input-7-4"
                        label="Reason"
                        v-model="reasonofcapture"
                        class="itemform"
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout
                wrap
                justify-center
                v-if="mitigation === '66a86f0c840cba1fbc0456ae'"
              >
                <v-flex xs12>
                  <span>
                    <v-text-field
                      outlined
                      label="How far into forest?"
                      placeholder="Enter How far into forest?"
                      v-model="farintoforest"
                      type="number"
                      class="inputPrice itemform"
                      dense
                    ></v-text-field>
                  </span>
                </v-flex>
              </v-layout>
              <v-layout
                wrap
                justify-center
                v-if="
                  releasedornot === 'No' ||
                  mitigation === 'Provide Safe Passage' ||
                  resolvedornot === 'Yes'
                "
              >
                <v-flex xs12 sm4 md4 lg4>
                  <v-text-field
                    outlined
                    label="Resolve Location"
                    placeholder="Enter Resolved Location"
                    v-model="resolvelocation"
                    type="text"
                    class="itemform"
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                  <v-select
                    :items="veterinarypresentornot"
                    label="Is veterinary present?"
                    outlined
                    v-model="veterinarypresent"
                    class="itemform"
                    dense
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm4 md4 lg4 pl-lg-2 pl-md-2 pl-sm-2>
                  <span v-if="veterinarypresent === 'Yes'">
                    <v-textarea
                      outlined
                      name="input-7-4"
                      label="Veterinary Recomendation"
                      v-model="veterinaryrecomendation"
                      class="itemform"
                      dense
                    ></v-textarea>
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center v-if="resolvedornot === 'No'">
            <v-flex xs12>
              <v-layout
                wrap
                justify-center
                v-if="ifnotresolved === 'Handing Over'"
              >
                <v-flex xs12 sm6 md6 lg6>
                  <span>
                    <v-select
                      :items="HandingOverTypes"
                      label="Handing Over To?"
                      outlined
                      v-model="handoverDetails.handoverplatform"
                      class="itemform"
                      dense
                    ></v-select>
                  </span>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  md6
                  lg6
                  pl-lg-2
                  pl-md-2
                  pl-sm-2
                  v-if="handoverDetails.handoverplatform === 'Division'"
                >
                  <span>
                    <v-autocomplete
                      outlined
                      label="Division Name"
                      :items="divisionList"
                      v-model="handoverDetails.divisionId"
                      item-text="divisionName"
                      item-value="_id"
                      class="itemform"
                      dense
                    >
                    </v-autocomplete>
                  </span>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  md6
                  lg6
                  pl-lg-2
                  pl-md-2
                  pl-sm-2
                  v-if="handoverDetails.handoverplatform === 'Range'"
                >
                  <span>
                    <v-autocomplete
                      outlined
                      label="Range Name"
                      :items="rangeList"
                      v-model="handoverDetails.rangeId"
                      item-text="rangeName"
                      item-value="_id"
                      class="itemform"
                      dense
                    >
                    </v-autocomplete>
                  </span>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  md6
                  lg6
                  pl-lg-2
                  pl-md-2
                  pl-sm-2
                  v-if="handoverDetails.handoverplatform === 'RRT'"
                >
                  <span>
                    <v-autocomplete
                      outlined
                      label="RRT Name"
                      :items="rrtList"
                      v-model="handoverDetails.RRT"
                      item-text="name"
                      item-value="_id"
                      class="itemform"
                      dense
                    >
                    </v-autocomplete>
                  </span>
                </v-flex>
              </v-layout>
              <v-layout
                wrap
                justify-center
                v-if="ifnotresolved === 'Steps taken for mitigation'"
              >
                <v-flex xs12>
                  <v-layout
                    wrap
                    justify-center
                    v-for="(step, index) in mitigationSteps"
                    :key="index"
                  >
                    <v-flex xs12 sm5 md6 lg6>
                      <v-select
                        :items="MitigationSteptypes"
                        label="Mitigation Steps"
                        outlined
                        v-model="step.name"
                        class="itemform"
                        dense
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm4 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2>
                      <v-menu
                        v-model="step.menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="step.mitigationStartDate"
                            label="Mitigation Start Date"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            class="itemform"
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="step.mitigationStartDate"
                          @input="step.menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 sm3 md3 lg3 pl-lg-2 pl-md-2 pl-sm-2 pt-1>
                      <span v-if="mitigationSteps.length > 1">
                        <v-btn
                          small
                          plain
                          @click="removeMitigationStep(index)"
                          color="error"
                          class="itemform"
                          dense
                        >
                          <span>Remove</span
                          ><span><v-icon small>mdi-delete</v-icon></span>
                        </v-btn>
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap justify-start>
                    <v-flex xs12 sm3 md3 lg3>
                      <v-btn
                        small
                        plain
                        @click="addMitigationStep"
                        color="primary"
                      >
                        <span><v-icon small>mdi-plus</v-icon></span
                        ><span>ADD More</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout wrap justify-start pb-2>
            <v-flex xs12 align-self-center>
              <span class="SubitemHeading1">Resolve Images</span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-start>
            <v-flex xs12>
              <v-layout wrap justify-center pa-5 style="border: solid 1px">
                <v-flex xs12 text-left>
                  <v-layout wrap justify-start>
                    <v-flex
                      xs12
                      sm3
                      md3
                      lg3
                      pa-5
                      v-for="(items, index) in displayImage"
                      :key="index"
                    >
                      <v-img
                        :src="items"
                        fill-height
                        height="100%"
                        width="100%"
                      >
                        <v-layout wrap>
                          <v-flex text-right pa-0>
                            <v-avatar color="#FF3434" size="15">
                              <v-icon
                                color="#FFF"
                                small
                                @click="removeImage(index)"
                              >
                                mdi-close
                              </v-icon>
                            </v-avatar>
                          </v-flex>
                        </v-layout>
                      </v-img>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-end>
                    <v-flex xs12 text-right>
                      <v-btn
                        color="blue-grey"
                        dark
                        small
                        @click="$refs.uploadDoc.click()"
                        class="itemform"
                        ><span> UPLOAD IMAGES </span>
                        <v-icon right dark> mdi-cloud-upload </v-icon></v-btn
                      >
                    </v-flex>
                    <input
                      v-show="false"
                      id="file1"
                      ref="uploadDoc"
                      multiple
                      type="file"
                      accept=".svg, .png, .jpg, .jpeg"
                      @change="uploadDoc"
                    />
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            class="itemValue"
            @click="updatemitigationdialog = false"
            >Close</v-btn
          >
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            class="itemValue"
            @click="updatemitigationsteps()"
            >UPDATE</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-expansion-panel>
</template>
<script>
// import EditDetails from "./editCapturedDetails";
import CarouselImage from "@/components/Cases/resolveImages";
import axios from "axios";
export default {
  props: ["caseDetails"],
  components: { CarouselImage },
  data() {
    return {
      dialog: false,
      selectedItem: "",
      dialog1: false,
      updatemitigationdialog: false,
      selectedImage: "",
      owlResponsive: {
        0: { items: 1, nav: false },
        600: { items: 1, nav: false },
        960: { items: 1, nav: false },
        1264: { items: 1, nav: false },
        1920: { items: 1, nav: false },
      },
      resolvedornotType: ["Yes", "No"],
      resolvedornot: "",
      MitigationTypes: [],
      mitigation: "",
      IfnotResolvedTypes: ["Handing Over", "Steps taken for mitigation"],
      ifnotresolved: "",
      MitigationmethodTypes: [],
      mitigationmethods: "",
      ReleasedornotTypes: ["Yes", "No"],
      releasedornot: "",
      whynotreleasedTypes: ["Treatment", "Permanent"],
      whynotreleased: "",
      locationnamenotresolved: "",
      menu5: false,
      dateofcapture: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      menu4: false,
      timeofcapture: null,
      reasonofcapture: "",
      farintoforest: "",
      resolvelocation: "",
      veterinarypresentornot: ["Yes", "No"],
      veterinarypresent: "",
      HandingOverTypes: ["Division", "Range", "RRT"],
      handoverDetails: {
        handoverplatform: "",
        divisionId: "",
        rangeId: "",
        RRT: "",
      },
      mitigationSteps: [
        {
          name: "",
          mitigationStartDate: "",
          menu: false,
        },
      ],
      MitigationSteptypes: [
        "Deploy Camera Trap",
        "Deploy Trap Cage",
        "Initiated Intensified Patroling",
        "Started chasing the animal",
      ],
      displayImage: [],
      appLoading: false,
      msg: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      showSnackBar: false,
      divisionList: [],
      rangeList: [],
      rrtList: [],
      docs1: [],
      docs1Public: [],
      docsPublic: [],
      docs: [],
      userList: [],
      userArray: [],
      newArray: [],
      doc: null,
      file: null,
      keyword: "",
      registerAssets: {},
      assetId: "",
      search: "",
      Rules: [(value) => !!value || "Required."],
      fileArray: [],
      byte: "",
      kb: "",
      mb: "",
      gb: "",
      veterinaryrecomendation: "",
      timeout: 5000,
      ServerError: false,
    };
  },
  beforeMount() {
    this.getmitigationtypeData();
    this.getDivisionData();
    this.getRangeData();
    this.getRRTData();
  },
  created() {
    this.setCurrentTime();
  },
  watch: {
    mitigation() {
      this.mitigationmethods = "";
      this.releasedornot = "";
      this.getmitigationmethodtypeData();
    },
  },
  computed: {
    appUser() {
      return this.$store.state.userRole;
    },
  },
  methods: {
    updatemitigationsteps() {
      // if (this.veterinarypresent === "Yes" && !this.veterinaryrecomendation) {
      //   this.msg = "Please Provide Veterinary Recommendation.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (this.resolvedornot === "Yes" && !this.mitigation) {
      //   this.msg = "Please Provide Mitigation Type.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (this.handingover === "Division" && !this.divisionname) {
      //   this.msg = "Please Provide Division Name.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (this.handingover === "Range" && !this.rangename) {
      //   this.msg = "Please Provide Range Name.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (this.handingover === "RRT" && !this.rrtname) {
      //   this.msg = "Please Provide RRT Name.";
      //   this.showSnackBar = true;
      //   return;
      // }
      const formData = new FormData();
      if (this.resolvedornot === "Yes") {
        formData.append("isResolved", true);
      }
      if (this.resolvedornot === "No") {
        formData.append("isResolved", false);
      }
      if (this.mitigation) {
        formData.append("mitigationTypeId", JSON.stringify(this.mitigation));
      }
      if (this.mitigationmethods) {
        formData.append(
          "mitigationMethodId",
          JSON.stringify(this.mitigationmethods)
        );
      }
      if (this.farintoforest) {
        formData.append("howFarintoForest", JSON.stringify(this.farintoforest));
      }
      if (this.releasedornot === "Yes") {
        formData.append("isReleased", true);
      }
      if (this.releasedornot === "No") {
        formData.append("isReleased", false);
      }
      if (this.whynotreleased === "Treatment") {
        formData.append("isTreatment", true);
        formData.append(
          "treatmentDetails",
          JSON.stringify(this.formattreatmentDetails())
        );
      }
      if (this.whynotreleased === "Permanent") {
        formData.append("isPermanent", true);
        formData.append(
          "permanentDetails",
          JSON.stringify(this.formatpermenantDetails())
        );
      }
      if (this.resolvelocation) {
        formData.append("resolvedLocationName", this.resolvelocation);
      }
      if (this.veterinarypresent === "Yes") {
        formData.append("isVeterinaryPresent", true);
        formData.append(
          "veterinaryRecommendations",
          this.veterinaryrecomendation
        );
      }
      if (this.veterinarypresent === "No") {
        formData.append("isVeterinaryPresent", false);
      }
      if (this.ifnotresolved === "Handing Over") {
        formData.append("isHandover", true);
      }

      if (this.ifnotresolved === "Steps taken for mitigation") {
        formData.append(
          "stepsForMitigation",
          JSON.stringify(this.mitigationSteps)
        );
        formData.append("isStepsForMitigation", true);
      }
      if (this.ifnotresolved === "Handing Over") {
        formData.append(
          "handoverDetails",
          JSON.stringify(this.handoverDetails)
        );
      }
      formData.append("id", this.selectedItem._id);
      for (let i = 0; i < this.docs.length; i++) {
        formData.append("resolveImages", this.docs[i]);
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/resolve/stepsformitigation/update",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
        data: formData,
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Case Resolved Successfully!";
              this.showSnackBar = true;
              this.appLoading = false;
              // window.location.reload();
              this.$router.push({
                path: "/officers/dashboard?id=resolvedCases",
              });
            }
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          // this.ServerError = true;
          console.log(err);
        });
    },
    formattreatmentDetails() {
      return {
        treatmentPlace: this.locationnamenotresolved,
        treatmentDate: this.dateofcapture,
        treatmentTime: this.timeofcapture,
        reason: this.reasonofcapture,
      };
    },
    formatpermenantDetails() {
      return {
        permanentPlace: this.locationnamenotresolved,
        permanentDate: this.dateofcapture,
        permanentTime: this.timeofcapture,
        reason: this.reasonofcapture,
      };
    },
    removeImage(index) {
      this.displayImage.splice(index, 1);
      this.docs.splice(index, 1);
      this.fileArray.splice(index, 1);
    },
    uploadDoc(event) {
      this.docs1 = event.target.files || event.dataTransfer.files;

      if (this.docs.length < 10) {
        for (let i = 0; i < this.docs1.length; i++) {
          if (this.fileArray.includes(this.docs1[i].name)) {
            this.msg = "Duplicate File";
            this.showSnackBar = true;
          } else {
            const urls = URL.createObjectURL(this.docs1[i]);
            this.displayImage.push(urls);
            this.docs.push(this.docs1[i]);
            console.log("file", this.docs1[i]);
            console.log("haii img", this.docs);
          }
          this.fileArray.push(this.docs1[i].name);
          this.checkbox = false;
          // this.checkbox1 = false;
          this.checkbox3 = false;
          // this.checkbox2 = false;
          this.docs1[i].sizeCopy = this.docs1[i].size + "Bytes";
          if (this.docs1[i].size >= 1024) {
            this.byte = this.docs1[i].size;
            this.kb = this.byte / 1024;
            if (this.kb >= 1024) {
              this.mb = (this.byte / 1048576).toFixed(2);
              if (this.mb >= 1024) {
                this.gb = (this.byte / 1073741824).toFixed(2);
                this.docs1[i].sizeCopy = this.gb + "GB";
              } else {
                this.docs1[i].sizeCopy = this.mb + "MB";
              }
            } else {
              this.docs1[i].sizeCopy = this.kb.toFixed(2) + "KB";
            }
          }
        }
        // this.addToArray();
      } else {
        this.msg = "Maximum 10 can be uploaded";
        this.showSnackBar = true;
      }
    },
    addMitigationStep() {
      this.mitigationSteps.push({
        name: "",
        mitigationStartDate: "",
        menu: false,
      });
    },
    removeMitigationStep(index) {
      this.mitigationSteps.splice(index, 1);
    },
    getDivisionData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/platform/division/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.divisionList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRangeData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/platform/range/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rangeList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRRTData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rescuer/rrt/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rrtList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getmitigationmethodtypeData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/mitigationMethod/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          mitigationTypeId: this.mitigation,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.MitigationmethodTypes = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getmitigationtypeData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/mitigationType/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.MitigationTypes = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    setCurrentTime() {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      this.time = `${hours}:${minutes}`;
      this.timeofcapture = `${hours}:${minutes}`;
    },
    formatTime24To12(time24) {
      if (!time24) return "-";
      let [hours, minutes] = time24.split(":").map(Number);
      const suffix = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      return `${hours}:${minutes.toString().padStart(2, "0")} ${suffix}`;
    },
    showMoreDetails(item) {
      this.selectedItem = item;
      this.dialog = true;
    },
    updatemitigationdialogbox(item) {
      this.selectedItem = item;
      this.updatemitigationdialog = true;
    },
    openImageDialog(image) {
      this.selectedImage = image;
      this.dialog1 = true;
    },
    formatDate(date) {
      if (!date) return "-";
      const formattedDate = new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(new Date(date));
      return formattedDate;
    },
    winStepper(window_data) {
      if (window_data.type == "reportActions") {
        this.$emit("stepper", {
          type: window_data.type,
        });
      }
    },
    timeSince(time) {
      switch (typeof time) {
        case "number":
          break;
        case "string":
          time = +new Date(time);
          break;
        case "object":
          if (time.constructor === Date) time = time.getTime();
          break;
        default:
          time = +new Date();
      }
      var time_formats = [
        [60, "seconds", 1], // 60
        [120, "1 minute ago", "1 minute from now"], // 60*2
        [3600, "minutes", 60], // 60*60, 60
        [7200, "1 hour ago", "1 hour from now"], // 60*60*2
        [86400, "hours", 3600], // 60*60*24, 60*60
        [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
        [604800, "days", 86400], // 60*60*24*7, 60*60*24
        [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
        [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
        [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
        [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
        [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
      ];
      var seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

      if (seconds == 0) {
        return "Just now";
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
      }
      var i = 0,
        format;
      while ((format = time_formats[i++]))
        if (seconds < format[0]) {
          if (typeof format[2] == "string") return format[list_choice];
          else
            return (
              Math.floor(seconds / format[2]) + " " + format[1] + " " + token
            );
        }
      return time;
    },
  },
};
</script>
<style>
.mainheading {
  font-family: sofiaProLight;
  font-size: 14px;
  color: #4d4d4d;
}
.subheading {
  font-family: sofiaProRegular !important;
  font-size: 16px !important;
  color: #000 !important;
  line-height: 0.5 !important;
}
.subsubhead {
  font-family: sofiaProRegular;
  font-size: 12px;
  color: #000;
  list-style: 0.5;
}
.mainmainhead {
  font-family: sofiaProLight;
  font-size: 13px;
  color: #4d4d4d;
}
</style>
<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #ffffff;
}
</style>