var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.caseDetails.releaseDetails.releasedStatus)?_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_c('span',{staticClass:"itemHeading"},[_vm._v("Released Details")])]),_c('v-expansion-panel-content',[_c('v-layout',{attrs:{"wrap":"","pt-4":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"pt-2":"","wrap":""}},[_c('v-flex',{attrs:{"xs9":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"sofiaProLight","font-size":"13px","color":"#4d4d4d"}},[_vm._v("Released By")]),_c('br'),_c('span',{staticStyle:{"font-family":"sofiaProRegular","font-size":"12px","color":"#000","line-height":"0.5"}},[(
                      _vm.caseDetails.releaseDetails.releasedBy.role === 'User'
                    )?_c('span',[_c('span',[_vm._v(_vm._s(_vm.caseDetails.releaseDetails.releasedBy.userId.name))])]):(
                      _vm.caseDetails.releaseDetails.releasedBy.role === 'MASTER'
                    )?_c('span',[_c('span',[_vm._v(_vm._s(_vm.caseDetails.releaseDetails.releasedBy.admin .masterName)+" (Admin)")])]):(
                      _vm.caseDetails.releaseDetails.releasedBy.role ===
                      'EmergencyUser'
                    )?_c('span',[_c('span',[_vm._v(_vm._s(_vm.caseDetails.releaseDetails.releasedBy.userId.name)+" (Emergency User)")])]):(
                      _vm.caseDetails.releaseDetails.releasedBy.role ===
                      'RANGE'
                    )?_c('span',[_c('span',[_vm._v(_vm._s(_vm.caseDetails.releaseDetails.releasedBy.range.accessCredentials.username)+" (Range Officer)")])]):(
                      _vm.caseDetails.releaseDetails.releasedBy.role ===
                      'DIVISION'
                    )?_c('span',[_c('span',[_vm._v(_vm._s(_vm.caseDetails.releaseDetails.releasedBy.division.accessCredentials.username)+" (Division Officer)")])]):(
                      _vm.caseDetails.releaseDetails.releasedBy.role ===
                      'RRT'
                    )?_c('span',[_c('span',[_vm._v(_vm._s(_vm.caseDetails.releaseDetails.releasedBy.userId.name)+" (RRT)")])]):_c('span',[_vm._v("UnKnown ")])])])],1)],1),_c('v-flex',{attrs:{"xs3":""}},[(_vm.caseDetails.releaseDetails.releasedTime)?_c('span',{staticClass:"float-right",staticStyle:{"font-family":"sofiaProRegular","color":"#000","font-size":"10px"}},[_vm._v(" "+_vm._s(_vm.timeSince(_vm.caseDetails.releaseDetails.releasedTime))+" ")]):_vm._e()])],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md6":"","sm6":"","lg6":""}},[_c('span',{staticStyle:{"font-family":"sofiaProLight","font-size":"13px","color":"#4d4d4d"}},[_vm._v("Released Location")]),_c('br'),_c('span',{staticStyle:{"font-family":"sofiaProRegular","font-size":"12px","color":"#000","line-height":"0.5"}},[(_vm.caseDetails.releaseDetails.locationName)?_c('span',[_vm._v(_vm._s(_vm.caseDetails.releaseDetails.locationName))]):_c('span',[_vm._v("UnKnown ")])])]),_c('v-flex',{attrs:{"xs12":"","md6":"","sm6":"","lg6":""}},[_c('span',{staticStyle:{"font-family":"sofiaProLight","font-size":"11px","color":"#4d4d4d"}},[_vm._v("Is Delayed?")]),_c('br'),_c('span',{staticStyle:{"font-family":"sofiaProRegular","font-size":"12px","color":"#000","line-height":"0.5"}},[(_vm.caseDetails.releaseDetails)?_c('span',[(_vm.caseDetails.releaseDetails.isDelayed === true)?_c('span',[_vm._v("Yes")]):(_vm.caseDetails.releaseDetails.isDelayed === false)?_c('span',[_vm._v("No")]):_vm._e()]):_vm._e()])]),(_vm.caseDetails.releaseDetails.isDelayed)?_c('v-flex',{attrs:{"xs12":"","md6":"","sm6":"","lg6":""}},[_c('span',{staticStyle:{"font-family":"sofiaProLight","font-size":"11px","color":"#4d4d4d"}},[_vm._v("Delayed Reason")]),_c('br'),_c('span',{staticStyle:{"font-family":"sofiaProRegular","font-size":"12px","color":"#000","line-height":"0.5"}},[(
                  _vm.caseDetails.releaseDetails.isDelayed &&
                  _vm.caseDetails.releaseDetails.delayreason
                )?_c('span',[_vm._v(_vm._s(_vm.caseDetails.releaseDetails.delayreason))]):_c('span',[_vm._v("- ")])])]):_vm._e(),_c('v-flex',{attrs:{"xs12":"","md6":"","sm6":"","lg6":""}},[_c('span',{staticStyle:{"font-family":"sofiaProLight","font-size":"11px","color":"#4d4d4d"}},[_vm._v("Animal Health")]),_c('br'),_c('span',{staticStyle:{"font-family":"sofiaProRegular","font-size":"12px","color":"#000","line-height":"0.5"}},[(_vm.caseDetails.releaseDetails.animalHealthId)?_c('span',[_vm._v(_vm._s(_vm.caseDetails.releaseDetails.animalHealthId.name))]):_c('span',[_vm._v("- ")])])]),_c('v-flex',{attrs:{"xs12":"","md6":"","sm6":"","lg6":""}},[_c('span',{staticStyle:{"font-family":"sofiaProLight","font-size":"11px","color":"#4d4d4d"}},[_vm._v("Mishaps")]),_c('br'),_c('span',{staticStyle:{"font-family":"sofiaProRegular","font-size":"12px","color":"#000","line-height":"0.5"}},[(_vm.caseDetails.releaseDetails.mishaps)?_c('span',[_vm._v(_vm._s(_vm.caseDetails.releaseDetails.mishaps))]):_c('span',[_vm._v("- ")])])]),_c('v-flex',{attrs:{"xs12":"","md6":"","sm6":"","lg6":""}},[_c('span',{staticStyle:{"font-family":"sofiaProLight","font-size":"11px","color":"#4d4d4d"}},[_vm._v("Remarks")]),_c('br'),_c('span',{staticStyle:{"font-family":"sofiaProRegular","font-size":"12px","color":"#000","line-height":"0.5"}},[(_vm.caseDetails.releaseDetails.remarks)?_c('span',[_vm._v(_vm._s(_vm.caseDetails.releaseDetails.remarks))]):_c('span',[_vm._v("- ")])])]),(
              _vm.caseDetails.releaseDetails &&
              _vm.caseDetails.releaseDetails.releaseImages.length > 0
            )?_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"sofiaProLight","font-size":"11px","color":"#4d4d4d"}},[_vm._v("Released Images")])]):_vm._e(),(
              _vm.caseDetails.releaseDetails &&
              _vm.caseDetails.releaseDetails.releaseImages.length > 0
            )?_c('v-flex',{attrs:{"xs12":""}},[_c('OwlCarousel',{attrs:{"nav":false,"dots":true,"responsive":_vm.owlResponsive}},[_vm._l((_vm.caseDetails.releaseDetails.releaseImages),function(item,i){return [(item)?_c('CarouselImage',{key:i,attrs:{"storage":item}}):_vm._e()]})],2)],1):_vm._e()],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }