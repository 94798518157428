var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-snackbar',{attrs:{"color":_vm.appColor,"right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"text-left":"","align-self-center":""}},[_vm._v(_vm._s(_vm.msg))]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{staticClass:"pa-2",staticStyle:{"cursor":"pointer"},attrs:{"elevation":"2","ripple":false,"tile":""},on:{"click":function($event){_vm.assignDialogue = true}}},[_c('span',{staticStyle:{"font-family":"sofiaProSemiBold","color":"#000","font-size":"14px"}},[_vm._v(" Self Assign ")])]),_c('v-dialog',{attrs:{"width":_vm.$vuetify.breakpoint.name == 'xs' ||
          _vm.$vuetify.breakpoint.name == 'sm' ||
          _vm.$vuetify.breakpoint.name == 'md'
            ? '100vw'
            : _vm.$vuetify.breakpoint.name == 'lg'
            ? '35vw'
            : '50vw'},model:{value:(_vm.assignDialogue),callback:function ($$v) {_vm.assignDialogue=$$v},expression:"assignDialogue"}},[_c('v-card',[_c('v-layout',{attrs:{"pa-4":"","wrap":"","justify-center":"","pa-8":""}},[_c('v-flex',{attrs:{"xs9":"","text-center":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs11":""}},[_c('v-icon',{attrs:{"size":"35","color":_vm.appColor}},[_vm._v("mdi-check-circle-outline")]),_c('span',{staticClass:"pl-2 itemSubHeading"},[_vm._v("Self Assigned")])],1),_c('v-flex',{attrs:{"xs12":"","pt-5":""}},[_c('v-btn',{staticStyle:{"letter-spacing":"0px"},attrs:{"x-small":"","depressed":"","dense":"","ripple":false,"color":"#EdEdEd"}},[(_vm.caseDetails.animalId)?_c('span',{staticStyle:{"font-family":"sofiaProRegular","color":"#000","font-size":"10px"}},[_vm._v(_vm._s(_vm.caseDetails.animalId.name)+" ")]):_vm._e()])],1),_c('v-flex',{attrs:{"xs12":"","py-2":""}},[(_vm.caseDetails.locationname)?_c('span',{staticStyle:{"font-family":"sofiaProRegular","font-size":"13px","color":"#000"}},[_c('span',[_vm._v("Location:")]),_vm._v(" "+_vm._s(_vm.caseDetails.locationname)+" ")]):_vm._e(),_c('br'),(_vm.formattedReportDate)?_c('span',{staticStyle:{"font-family":"sofiaProRegular","font-size":"13px","color":"#000"}},[_c('span',[_vm._v("Reported Date:")]),_vm._v(" "+_vm._s(_vm.formattedReportDate)+" ")]):_vm._e()]),_c('v-flex',{attrs:{"xs6":"","offset-3":"","pt-4":""}},[_c('v-btn',{attrs:{"block":"","small":"","color":_vm.appColor,"dark":"","ripple":false,"depressed":"","type":"submit"},on:{"click":function($event){return _vm.assign('self')}}},[_c('span',{staticClass:"text-capitalize"},[_vm._v(" CONTINUE ")])])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }